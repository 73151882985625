<template>
  <section class="bg-grey mt-5">
    <section class="container">
      <div class="card disen cardd px-3 py-3">
        <div
          class="row justify-content-end"
          style="padding-right: 15px; padding-bottom: 24px"
        >
          <a-month-picker
            v-model:value="month"
            placeholder="Seleccionar mes"
            @change="onChangeMonth"
          />
        </div>
        <a-table
          :columns="columns"
          :data-source="data()"
          @change="onChange"
          :scroll="{ x: 1300 }"
        >
          <template #products="{ record }">
            <li v-for="product in record.products" :key="product.product.name">
              {{ product.product.name }}
              (x{{ product.cant }}) (${{ product.product.price }})
            </li>
          </template>
          <template #total_price="{ record }">
            {{
              (
                (record.products_price ?? 0) +
                (record.delivery_price ?? 0) +
                (record.price_service ?? 0)
              ).toFixed(2)
            }}
          </template>
        </a-table>
      </div>
    </section>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import moment from "moment";
import { getHistoryByAlly } from "../../../router/auth_guard";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Fecha",
    dataIndex: "day",
    defaultSortOrder: "descend",
    sorter: (a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0),
  },
  {
    title: "Productos",
    // dataIndex: "order_data",
    slots: { customRender: "products" },
  },
  {
    title: "Ganancia adquirida",
    dataIndex: "products_price",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.products_price > b.products_price
        ? 1
        : a.products_price < b.products_price
        ? -1
        : 0,
  },
  // Será la sumatoria de todas las comisiones
  {
    title: "Comisión",
    dataIndex: "ally_ref.commission",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.ally_ref.commission > b.ally_ref.commission
        ? 1
        : a.ally_ref.commission < b.ally_ref.commission
        ? -1
        : 0,
  },
  {
    title: "Precio total",
    slots: { customRender: "total_price" },
  },
];

export default {
  setup() {
    const store = useStore();
    const onChange = (pagination, filters, sorter) => {
      console.log("params", pagination, filters, sorter);
    };
    const onChangeMonth = async (date) => {
      await store.dispatch("getHistoryByAlly", date);
    };
    const data = () => store.getters.ordersFromHistory;
    return {
      data,
      columns,
      month: ref(moment()),
      onChangeMonth,
      onChange,
    };
  },
  beforeRouteEnter: getHistoryByAlly,
};
</script>

<style>
.ant-table-body {
  overflow-x: auto !important;
}
</style>
<style scoped src="../styles/style.css"></style>